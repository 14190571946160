import * as React from 'react';
import Layout from '../components/layout';
import FOIComplaintForm from '../components/foi-complaint-form';
const formConfig = require('../components/foi-complaint-form.json');

const FOIComplaintPage = () => (
  <Layout
    title={formConfig.title}>
    <FOIComplaintForm 
    />
  </Layout>
)

export default FOIComplaintPage;
